import { api } from "./api";
import { endpoints } from "./endpoints";
import { checkLoggedIn, groupIsFull } from "utils/functions";
import { GroupType, GroupTypeInList } from "models/group/GroupModel";
import { GroupIsFullError } from "@errors/groups";
import axios from "axios";

export type ApiGroupType = GroupType & {
    game: string;
    languages?: string;
    group_size: string;
    game_platform: string;
};

type ManageGroupPayload = Omit<GroupType, "id | label | Creator | members | group_id | creator_id | creation_date">;

/**
 * Parses the response from the API and returns a GroupType object with the parsed data.
 *
 * @param {ApiGroupType} group - The API group response to parse.
 * @return {GroupType} The parsed GroupType object.
 */
function parseGroupsResponse(group: ApiGroupType): GroupType {
    return {
        ...group,
        // Apply type conversions
        game: parseInt(group["game"] as string) as number,
        game_platform: parseInt(group["game_platform"] as string) as number,
        group_size: parseInt(group["group_size"] as string) as number,
        languages: parseInt(group.languages),

        // Translate cost to number if it's a string and it exists on group
        ...(group.cost !== undefined && { cost: typeof group.cost === "string" ? parseFloat(group.cost) : group.cost }),

        // Update members
        members: group.members?.map((member) => ({
            ...member,
            // Round matchscore to nearest integer
            matchScore: Math.round(member.matchScore || 0),
        })),
    };
}

/**
 * Creates a new group by sending a POST request to the API with the provided parameters.
 *
 * @param {ManageGroupPayload} params - The parameters for creating the group.
 * @return {Promise<GroupType>} A Promise that resolves to the created group. If an error occurs the error will be in a string
 */
export async function createGroup(params: ManageGroupPayload): Promise<GroupType> {
    const logged = await checkLoggedIn();

    const response: ApiGroupType | string = (
        await api.post(logged ? endpoints.groups : endpoints.publicGroups, {
            ...params,
            ...(params.cost && { cost: params.cost.toString() }),
        })
    ).data;

    if (typeof response === "string") throw new Error(response);

    return parseGroupsResponse(response);
}

/**
 * Fetches a group from the API based on the provided group ID.
 *
 * @param {string} groupId - The ID of the group to fetch.
 * @return {Promise<GroupType | string>} A Promise that resolves to the fetched group. If the group is not found, it resolves to "Group not found".
 */
export async function getGroup(groupId: string): Promise<GroupType> {
    const logged = await checkLoggedIn();

    // Fetch group from API
    const response: ApiGroupType | string = (
        await api.get(logged ? endpoints.groups : endpoints.publicGroups, { params: { group_id: groupId } })
    ).data;

    if (typeof response === "string") throw new Error(response);

    return parseGroupsResponse(response);
}

/**
 * Updates a group with the given group ID using the provided data.
 *
 * @param {string} group_id - The ID of the group to be updated.
 * @param {ManageGroupPayload} body - The data to update the group with.
 * @return {Promise<GroupType>} A promise that resolves to the updated group.
 * @throws {Error} If the API response is a string.
 */
export async function updateGroup(group_id: string, body: ManageGroupPayload): Promise<GroupType> {
    const logged = await checkLoggedIn();

    const response: ApiGroupType | string = (
        await api.put(
            logged ? endpoints.groups : endpoints.publicGroups,
            { ...body, ...(body.cost && { cost: body.cost.toString() }) },
            { params: { group_id } },
        )
    ).data;

    if (typeof response === "string") throw new Error(response);

    return parseGroupsResponse(response);
}

/**
 * Deletes a group with the specified group ID.
 *
 * @param {string} group_id - The ID of the group to be deleted.
 * @return {Promise<boolean>} A Promise that resolves to true if the group is successfully deleted.
 */
export async function deleteGroup(group_id: string): Promise<boolean> {
    const logged = await checkLoggedIn();

    const response: boolean | string = (
        await api.delete(logged ? endpoints.groups : endpoints.publicGroups, { params: { group_id } })
    ).data;

    if (typeof response === "string") throw new Error(response);

    return response;
}

type RemovePlayerPayloadType = {
    group_id: string;
    member_id: string;
};

type RemovePlayerResponseType = "User removed from group successfully!" | string;

/**
 * Removes a player from a group.
 *
 * @param {RemovePlayerPayloadType} params - The parameters for removing a player (group_id, member_id)
 * @return {Promise<string>} A string indicating the result of the removal operation
 */
export async function removePlayer(params: RemovePlayerPayloadType): Promise<string> {
    const response: RemovePlayerResponseType = (await api.post(endpoints.removeGroupPlayer, params)).data;

    if (response !== "User removed from group successfully!") throw new Error(response);

    return response;
}

type LeaveGroupResponseType = "User left group successfully" | string;

/**
 * Leaves a group with the specified group ID.
 *
 * @param {string} group_id - The ID of the group to leave.
 * @return {Promise<string>} A Promise that resolves to a string indicating the result of the leave operation.
 * @throws {Error} If the API response is not "User left group successfully".
 */
export async function leaveGroup(group_id: string): Promise<string> {
    const response: LeaveGroupResponseType = (await api.post(endpoints.leaveGroup, { group_id })).data;

    if (response !== "User left group successfully") throw new Error(response);

    return response;
}

/**
 * Joins a group with the specified group ID.
 *
 * @param {string} group_id - The ID of the group to join.
 * @return {Promise<string>} A Promise that resolves to a string indicating the result of the join operation.
 */
export async function joinGroup(group_id: string, invitation?: boolean): Promise<string> {
    // Check if the group is full before trying to join
    const group = await getGroup(group_id);
    if (groupIsFull(group)) throw new GroupIsFullError("Group is full");

    // Join the group
    const response = await api
        .post<LeaveGroupResponseType>(endpoints.joinGroup, { group_id, invitation })
        .then((res) => res.data);

    // Check if the join was successful
    if (response !== "User joined the group!") throw new Error("Error joining group");
    return response;
}

type GetGroupsRequestType = {
    pageNumber?: number;
    resultsPerPage?: number;
} & { [key: string]: any };
/**
 * Retrieves all groups from the API without any filters.
 *
 * @return {Promise<GroupTypeInList>} A Promise that resolves to the list of groups.
 */
export async function getGroups(params: GetGroupsRequestType = {}): Promise<Array<GroupTypeInList>> {
    const logged = await checkLoggedIn();

    // Grab all groups from API without filters
    const response: Array<GroupTypeInList> | string = (
        await api.get(logged ? endpoints.groups : endpoints.publicGroups, { params: { ...params } })
    ).data;

    // Check for the presence of errors
    if (typeof response !== "object") throw new Error(response);

    // Return groups list
    return response.map((group) => {
        // Populate language with host's if the group doesn't have it
        if (group.languages === undefined) {
            const host = group.members?.find((member) => member.member_type === "host");
            if (host) {
                group.languages = host?.languages || 0;
            }
        }
        return group;
    });
}

/**
 * Invite users to a group
 * @param {string} groupId The ID of the group to invite users to
 * @param {Array<string>} users List of users to invite, both IDs and E-mails
 * @returns
 */
export async function inviteToGroup(groupId: string, users: Array<string>): Promise<any> {
    return (
        getGroup(groupId)
            // 1. Check if group is full and throw an error if it is
            .then((group) => {
                if ((group.members?.length || 0) >= group.group_size) throw new GroupIsFullError("Group is full");
            })
            // 2. Invite users to the group
            .then(() => {
                // Filter out duplicated users
                const uniqueUsers = Array.from(new Set(users)).filter(Boolean);

                return api.post(endpoints.inviteToGroup, {
                    groupId,
                    eventSource: "group.invite",
                    listOfStrings: uniqueUsers,
                    eventDetailType: "Group invite notification",
                });
            })
            // 3. Transform the response
            .then((res) => res.data)
    );
}

export function declineGroupInvitation(groupId: string) {
    return api
        .post<"Invitation declined!">(endpoints.declineGroupInvitation, { group_id: groupId })
        .then((res) => res.data)
        .catch((err) => {
            if (axios.isAxiosError(err)) throw new Error(err.response?.data);
            else throw err;
        });
}
