import { useQuery } from "react-query";
import React, { useContext, useEffect, useState } from "react";

import { CMSLanguages } from "models/cms/fileds";
import { GroupMemberType, GroupType } from "models/group/GroupModel";
import { ProfileModel } from "models/ProfileModels";

import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";

import { getGroup, joinGroup } from "@services/groupsProvider";
import { parseSchedule } from "@utils/schedule";
import { profileProvider } from "@services/profileProvider";
import { CMSContextContent, GameType, PlatformType } from "models/cms/fileds";
import { GroupsGameType } from "./useGroupListItem";
import { GlobalContextType } from "./useGroupsList";
import { useGlobalContext } from "context/AppContext";
import { useSetAtom } from "jotai";
import { loadingInvitationAtom } from "@atoms/groupsAtoms";

export function removeMember({
    memberId,
    groupState,
    setGroupState,
}: {
    memberId: string;
    groupState: Partial<GroupType>;
    setGroupState: React.Dispatch<React.SetStateAction<Partial<GroupType>>>;
}) {
    // Updating group state, removing the member with the given ID
    setGroupState({
        ...groupState,
        members: groupState?.members?.filter((member) => member.id !== memberId),
    });
}

export function addMember({
    member,
    groupState,
    setGroupState,
}: {
    member: GroupMemberType;
    groupState: Partial<GroupType>;
    setGroupState: React.Dispatch<React.SetStateAction<Partial<GroupType>>>;
}) {
    // Updating group state, adding the new member to the group
    setGroupState({
        ...groupState,
        members: groupState.members ? [...groupState.members, member] : [member],
    });
}

export function useGroup(groupId: string) {
    /* States and Hooks */
    const setLoadingInvitation = useSetAtom(loadingInvitationAtom);

    const [game, setGame] = useState<GroupsGameType | null>(null);
    const [platform, setPlatform] = useState<{ name: string; img: string } | null>(null);
    const [language, setLanguage] = useState<CMSLanguages | null>(null);
    const [hostProfile, setHostProfile] = useState<ProfileModel | null>(null);
    const [hostLanguages, setHostLanguages] = useState<Array<string>>([]);
    const [schedule, setSchedule] = useState<{ weekday: string; scheduleStart: string; scheduleEnd: string } | null>(
        null,
    );
    const { filterFields }: CMSContextContent = useContext(AsyncFilterFieldsContext);
    const { personalProfile, isLoggedIn }: GlobalContextType = useGlobalContext();

    // Get the inheirited group state
    const inheiritedGroupState: GroupType | null = JSON.parse(sessionStorage.getItem("inheiritedGroupState") || "null");

    const [groupState, setGroupState] = useState<Partial<GroupType>>(
        inheiritedGroupState?.id === groupId ? inheiritedGroupState : {},
    );

    sessionStorage.removeItem("inheiritedGroupState");

    /* Variables */
    const userIsHost = groupState.creator_id === personalProfile?.id;
    const groupIsFull: boolean = (groupState?.members?.length || 0) >= (groupState.group_size || 0);

    /* Functions  */

    async function fetchGroup(): Promise<GroupType> {
        // Check if it have the required properties
        if (!groupId) throw new Error("Missing group ID");

        // Get the group with the ID
        return await getGroup(groupId);
    }

    async function fetchHostProfile() {
        // Check if it have the required properties
        if (!groupId || !groupState.creator_id) return;

        // Get the host profile with the ID, checking the matchscore as well
        const response: ProfileModel | null = (await profileProvider.getProfile(groupState.creator_id))?.data;

        // Check if there is a response
        if (!response?.id) throw new Error("Host profile not found");

        // Update the state with the host profile
        setHostProfile(response);
    }

    type handleJoinGroupProps = {
        invitation: boolean;
    };
    async function handleJoinGroup({ invitation }: handleJoinGroupProps) {
        const group = groupState;

        if (!group.id || userIsHost || !personalProfile?.name)
            throw new Error("Something went wrong, please try again.");

        setLoadingInvitation(true);
        await joinGroup(group.id, invitation).finally(() => setLoadingInvitation(false));

        const member: GroupMemberType = {
            id: personalProfile.id as string,
            name: personalProfile?.name?.[0],
            gender: personalProfile?.gender?.[0] || "",
            avatar_id: personalProfile?.avatar_id,
            avatar: personalProfile.profileURL?.[0] || "",
            profileUrl: personalProfile?.vanityUrl?.[0] || "",
            matchScore: 100,
            member_type: "player",
        };

        addMember({ member, groupState: group, setGroupState });
    }

    /* Lifecycle  */

    useEffect(() => {
        // Fill the data with CMS information, like languages, games, ...
        if (!filterFields) return;

        // Fill the game data
        const gameFound = filterFields?.games?.find((game: GameType) => game.id === groupState.game);
        if (gameFound) {
            setGame({
                ...gameFound,
                groupsImage: gameFound.groupsImage ? `https:${gameFound.groupsImage.fields.file.url}` : undefined,
            });
        }

        // Fill the VTT data
        const selectedPlatform = filterFields?.vtt?.find(
            (platform: PlatformType) => platform.id === groupState.game_platform,
        );

        setPlatform({
            name: selectedPlatform?.name || "",
            img: selectedPlatform?.img || "",
        });

        // Fill the language data
        setHostLanguages(
            filterFields?.languages
                ?.filter((language: CMSLanguages) => !!hostProfile?.languages?.find((id: number) => id === language.id))
                .map((language: CMSLanguages) => language.languageName),
        );

        const languageFound = filterFields.languages.find((language) => groupState.languages === language?.id);
        if (languageFound) setLanguage(languageFound);

        // Translate the schedule to the correct format
        if (!Boolean(groupState.schedule_start) && !Boolean(groupState.schedule_end)) return;

        const parsedStartSchedule = parseSchedule(groupState.schedule_start as string);
        const parsedEndSchedule = parseSchedule(groupState.schedule_end as string);

        if (parsedStartSchedule?.weekday && parsedStartSchedule.time && parsedEndSchedule?.time) {
            setSchedule({
                weekday: parsedStartSchedule?.weekday,
                scheduleStart: parsedStartSchedule?.getTime(),
                scheduleEnd: parsedEndSchedule?.getTime(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFields, groupState?.id, hostProfile?.id]);

    // Get the group
    const { isError } = useQuery({
        enabled: Boolean(groupId) && !groupState.id,
        queryKey: ["group", groupId],
        queryFn: fetchGroup,
        onSuccess: (data) => setGroupState(data),
        retry: 1,
    });

    // Get the host profile
    useQuery(["hostProfile", groupId, groupState.creator_id], fetchHostProfile, {
        enabled: !hostProfile?.id,
    });

    return {
        game,
        setGame,
        platform,
        language,
        schedule,
        groupState,
        setSchedule,
        userIsHost,
        hostProfile,
        setPlatform,
        setGroupState,
        hostLanguages,
        setHostProfile,
        handleJoinGroup,
        fetchHostProfile,
        setHostLanguages,
        errorLoadingGroup: isError,
    };
}
