import { api } from "./api";
import { endpoints } from "./endpoints";

type ChangeCognitoEmailResponse = string;

async function changeCognitoEmail(email: string): Promise<string> {
    return api.post<ChangeCognitoEmailResponse>(endpoints.changeEmail + "/cognito", { email }).then((res) => res.data);
}

type ChangeEmailResponse = {
    Message: "Success" | string;
    ExecutionID: string;
};
async function changeEmail(): Promise<ChangeEmailResponse> {
    return api
        .put<ChangeEmailResponse>(endpoints.changeEmail + "/initiate")
        .then((res): ChangeEmailResponse => res.data)
        .then((res): ChangeEmailResponse => {
            if (res.Message === "Success") localStorage.setItem("ChangeEmail.ExecutionID", res.ExecutionID);
            return res;
        });
}

type CheckEmailChangeStatusResponse = {
    Status: "in_progress" | "completed" | "failed" | "not_found" | "No execution found";
    Progress: string;
};
async function checkEmailChangeStatus(execution_id: string): Promise<CheckEmailChangeStatusResponse> {
    return api
        .get<CheckEmailChangeStatusResponse>(endpoints.changeEmail + "/check", { params: { execution_id } })
        .then((res) => res.data);
}

type GetEmailChangeResultResponse = {
    Message: "Results found" | "No results found";
    Results: {
        new_email: string;
        old_email: string;
        groups_member: Array<object>;
        update_progress: string;
        "old_email/new_email": string;
        execution_id: string;
        groups_own: Array<object>;
        update_status: CheckEmailChangeStatusResponse["Status"];
    };
};

async function getEmailChangeResult(execution_id: string): Promise<GetEmailChangeResultResponse> {
    return api
        .get<GetEmailChangeResultResponse>(endpoints.changeEmail + "/result", { params: { execution_id } })
        .then((res) => res.data);
}

const changeEmailProvider = {
    changeCognitoEmail,
    changeEmail,
    checkEmailChangeStatus,
    getEmailChangeResult,
};

export default changeEmailProvider;
