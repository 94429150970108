import "./Group.scss";

import React, { useContext, useState } from "react";

import { useSetAtom } from "jotai";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"; // Stay

import { Box, CircularProgress, Typography } from "@mui/material";
import { View } from "@aws-amplify/ui-react";
import { Close } from "@mui/icons-material";

import { GroupHeroView } from "@components/Groups/GroupHero";

import GroupPlayersView from "@components/Groups/GroupPlayers/GroupPlayersView";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { GroupHostGamingPreferencesView } from "@components/Groups/GroupHostGamingPreferences";

import { storage } from "@services/sessionStorage";
import { removeMember, useGroup } from "@hooks/useGroups";

import { GroupActions } from "@components/Groups/GroupActions";
import { AlertPopUpSmall } from "@components/AlertPopUp/AlertPopUpSmall";
import { useGlobalContext } from "context/AppContext";
import { LoginBanner } from "@components/LoginBanner/LoginBanner";
import { ShouldRender } from "@components/Profile/components/ShouldRender/ShouldRender";
import { GlobalContextType } from "hooks/useGroupsList";
import { SEOGroupMiddleWare } from "@components/SEO/SEOGroupMiddleWare";
import { usePopupController } from "@components/AlertPopUp";
import { GroupDescriptionView } from "@components/Groups/GroupDescription";

import {
    CannotJoinGroupDialog,
    CannotMessageHostDialog,
    DeleteGroupDialog,
    GroupFullDialog,
    JoinGroupDialog,
    JoinGroupWarningDialog,
} from "@components/Groups/GroupDialogs";

import { GroupType } from "models/group/GroupModel";
import { platformIsATable } from "utils/functions";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { CMSContextContent } from "models/cms/fileds";
import { GroupIsFullError } from "@errors/groups";
import { LoginContext } from "context/LoginContext";
import { useMessages } from "@pages/Inbox/hooks/useMessages";
import { declineGroupInvitation } from "@services/groupsProvider";
import { inviteAcceptedAtom } from "@atoms/groupsAtoms";

export type ProfileWithProfileImage = {
    name: string;
    gender: string;
    matchScore: number;
    profileURL: Array<string>;
    avatar_id: number;
};

export default function Group() {
    /* States and Hooks */
    const navigate = useNavigate(); // Stay
    const setInviteAccepted = useSetAtom(inviteAcceptedAtom);

    const { filterFields }: CMSContextContent = useContext(AsyncFilterFieldsContext);
    const { handleMessageClick } = useMessages();
    const { popup, showPopup } = usePopupController();
    const { personalProfile, isLoggedIn, isLoggedInVerified }: GlobalContextType = useGlobalContext();
    const { setLoginPopup } = useContext(LoginContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const [openJoinModal, setOpenJoinModal] = useState(false);
    const [groupIsFullDialog, setGroupIsFullDialog] = useState(false);
    const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);
    const [cannotJoinGroupModal, setCannotJoinGroupModal] = useState(false);
    const [cannotMessageHostModal, setCannotMessageHostModal] = useState(false);
    const [joinGroupWarningDialog, setJoinGroupWarningDialog] = useState(false);

    const { groupId } = useParams();
    const { isTablet, isMobile, isDesktop, isLaptop } = useBreakpoint();

    if (!groupId) throw new Error("Group ID not found");

    const {
        game,
        platform,
        schedule,
        language,
        userIsHost,
        groupState,
        hostProfile,
        setGroupState,
        handleJoinGroup,
        errorLoadingGroup,
    } = useGroup(groupId);

    /* Functions */

    function handleLogin() {
        if (cannotJoinGroupModal) setCannotJoinGroupModal(false);
        if (cannotMessageHostModal) setCannotMessageHostModal(false);
        storage.sessionStorage.surveyRedirect.setPath();
        setLoginPopup(true);
    }

    function handleMessageHost(): void {
        if (!isLoggedIn) {
            setCannotMessageHostModal(true);
            return;
        }

        try {
            if (!personalProfile?.id || !hostProfile?.id) return;

            handleMessageClick({ publicID: hostProfile?.id as string });
        } catch (error) {
            showPopup({ title: "Error", description: (error as Error).message, severity: "error" });
        }
    }

    type HandleInvitationProps = {
        event: "CHECK PLATFORM AND LOCATION" | "INVITE TO GROUP";
        payload: { group: Partial<GroupType>; invitation: boolean };
    };
    async function handleInvitation({ event, payload }: HandleInvitationProps) {
        try {
            switch (event) {
                case "CHECK PLATFORM AND LOCATION": {
                    const { group } = payload;

                    const platform = filterFields.vtt.find((vtt) => vtt.id === group.game_platform);

                    if (!platform?.name) return;

                    /* In case the location is not the same then open the warning */
                    if (platformIsATable(platform)) {
                        if (
                            group.city !== personalProfile?.city?.[0] ||
                            group.state !== personalProfile?.state?.[0] ||
                            group.country !== personalProfile?.country?.[0]
                        ) {
                            setOpenJoinModal(false);
                            setJoinGroupWarningDialog(true);
                            return;
                        }
                    }

                    return handleInvitation({ event: "INVITE TO GROUP", payload });
                }
                case "INVITE TO GROUP": {
                    const { invitation } = payload;

                    if (!isLoggedIn) {
                        // In case is not logged in then show login modal to users
                        if (!invitation) setCannotJoinGroupModal(true);
                        // In case of invitation flow, then show the login popup to users
                        throw new Error("You must be logged in to join a group");
                    }

                    await handleJoinGroup({ invitation }); // Join Group

                    setOpenJoinModal(true); // Show success dialog
                }
            }
        } catch (error) {
            if (error instanceof GroupIsFullError) setGroupIsFullDialog(true);
            throw error;
        } finally {
            setJoinGroupWarningDialog(false); // Hide warning dialog
        }
    }

    function commitInvitation() {
        if (Boolean(searchParams.get("invitation"))) {
            searchParams.delete("invitation");
            setSearchParams(searchParams);
        }
    }

    function handleAcceptInvite(): Promise<void> | void {
        if (!isLoggedIn) {
            setInviteAccepted(true);
            return handleLogin();
        }

        return handleInvitation({ event: "INVITE TO GROUP", payload: { group: groupState, invitation: true } }).then(
            commitInvitation,
        );
    }

    function handleDeclineInvite(): Promise<void> | void {
        if (!isLoggedInVerified) return;
        if (!isLoggedIn) return handleLogin();

        if (groupState.id) {
            return declineGroupInvitation(groupState.id)
                .then(() => {
                    showPopup({
                        title: "Invitation declined",
                        severity: "error",
                        description: `You have declined ${groupState.Creator}’s invitation to join this group.`,
                    });
                })
                .then(commitInvitation)
                .catch((err) =>
                    showPopup({
                        title: "Error",
                        severity: "error",
                        description: (err as Error).message ?? "Something went wrong. Please try again.",
                    }),
                );
        }
    }

    /* Variables */

    const groupName = `${groupState.Creator}'s Group | ${game?.name}`;

    const loading = !groupState.id || !hostProfile?.id;
    const joined: boolean = !!groupState.members?.find((member) => member.id === personalProfile?.id);

    const containerClasses: string[] = [
        "group-page",
        "fullscreen-mobile-group",
        "group-view-page",
        isMobile ? "mobile-group-page" : "",
        isTablet ? "tablet-group-page" : "",
        isDesktop || isLaptop ? "desktop-group-page" : "",
        userIsHost ? "host-view" : "",
        joined ? "joined-view" : "",
    ];

    /* Render */

    // Error handling
    if (errorLoadingGroup)
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                className={containerClasses.join(" ")}>
                <Box>
                    <Typography variant="h3">Group not found</Typography>
                    <Typography variant="h4">The group you are looking for does not exist.</Typography>
                    <ApplyButton onClick={() => navigate("/groups")}>Return to Groups List</ApplyButton>
                </Box>
            </Box>
        );

    // Loader
    if (loading)
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                className={containerClasses.join(" ")}>
                <CircularProgress color="primary" />
            </Box>
        );

    // Body
    return (
        <SEOGroupMiddleWare game={game} groupState={groupState} platform={platform}>
            {/* Popups */}
            <AlertPopUpSmall {...popup} />
            {/* Dialogs */}
            <DeleteGroupDialog open={openDeleteGroupDialog} onClose={setOpenDeleteGroupDialog} groupId={groupId} />
            <ShouldRender returnNull condition={!Boolean(isLoggedIn) && isLoggedInVerified}>
                <LoginBanner />
            </ShouldRender>
            <JoinGroupWarningDialog
                open={joinGroupWarningDialog}
                onUndo={() => setJoinGroupWarningDialog(false)}
                onConfirm={() => {
                    if (!groupState) return;
                    return handleInvitation({
                        event: "INVITE TO GROUP",
                        payload: { group: groupState, invitation: false },
                    });
                }}
            />
            <GroupFullDialog open={groupIsFullDialog} onClose={setGroupIsFullDialog} />
            <JoinGroupDialog
                open={openJoinModal}
                onUndo={() => {
                    setOpenJoinModal(false);
                    removeMember({
                        memberId: personalProfile?.id as string,
                        groupState,
                        setGroupState,
                    });
                }}
                onClose={() => setOpenJoinModal(false)}
                groupId={groupState.id || ""}
                creatorId={groupState?.creator_id}
                onConfirm={() => setOpenJoinModal(false)}
            />
            <CannotMessageHostDialog
                open={cannotMessageHostModal}
                onUndo={() => setCannotMessageHostModal(false)}
                onConfirm={handleLogin}
            />
            <CannotJoinGroupDialog
                open={cannotJoinGroupModal}
                onUndo={() => setCannotJoinGroupModal(false)}
                onConfirm={handleLogin}
            />
            {/* Component */}
            <View className={containerClasses.join(" ")}>
                <GroupHeroView
                    game={game || { name: "", id: 0, enabled: true, pageUrlPath: "" }}
                    host={hostProfile}
                    group={groupState}
                    joined={joined}
                    language={language}
                    weekday={schedule?.weekday || ""}
                    platform={platform || { name: "", img: "" }}
                    setGroup={setGroupState}
                    hostView={userIsHost}
                    groupName={groupName}
                    schedule_end={schedule?.scheduleEnd || ""}
                    schedule_start={schedule?.scheduleStart || ""}
                    personalProfile={personalProfile}
                />
                <Box className="group-components-container">
                    <GroupActions
                        group={groupState}
                        joined={joined}
                        platform={platform || { name: "", img: "" }}
                        hostView={userIsHost}
                        hostProfile={hostProfile}
                        handleLogin={handleLogin}
                        handleDeclineInvite={handleDeclineInvite}
                        handleAcceptInvite={handleAcceptInvite}
                        handleJoinGroup={(invitation: boolean) => {
                            if (!isLoggedIn) throw new Error("User is not logged in");

                            return handleInvitation({
                                event: "CHECK PLATFORM AND LOCATION",
                                payload: { group: groupState, invitation },
                            });
                        }}
                        handleMessageHost={handleMessageHost}
                    />
                    <GroupPlayersView
                        group={groupState}
                        joined={joined}
                        hostView={userIsHost}
                        setGroup={setGroupState}
                        personalProfile={personalProfile}
                        handleJoinGroup={() => {
                            if (!isLoggedIn) setCannotJoinGroupModal(true);
                            else
                                return handleInvitation({
                                    event: "CHECK PLATFORM AND LOCATION",
                                    payload: { group: groupState, invitation: false },
                                });
                        }}
                    />
                    <ShouldRender returnNull condition={Boolean(groupState.groups_description)}>
                        <GroupDescriptionView description={groupState.groups_description || ""} />
                    </ShouldRender>
                    <GroupHostGamingPreferencesView hostProfile={hostProfile} />
                    {userIsHost && (
                        <SecondaryApplyButton
                            icon={<Close fontSize="small" />}
                            onClick={() => setOpenDeleteGroupDialog(true)}
                            customClass="delete-group-button">
                            delete group
                        </SecondaryApplyButton>
                    )}
                </Box>
            </View>
        </SEOGroupMiddleWare>
    );
}
