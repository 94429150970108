import "./TransferingMessagesProgress.scss";

import React from "react";

import { Typography, LinearProgress, Box } from "@mui/material";

import { Info, Warning } from "@mui/icons-material";
import useTransferingMessages from "@hooks/useTransferingMessages";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

export default function TransferingMessagesProgress() {
    /* States and Hooks */
    const { transferingMessages, transferingMessagesProgress, errorTransferingMessages } = useTransferingMessages();

    /* Render */
    if (!transferingMessages && !errorTransferingMessages) return null;

    return (
        <Box className="transfering-messages-progress-container">
            <Box sx={{ display: "flex", gap: "8px" }}>
                {errorTransferingMessages ? (
                    <Warning color="primary" fontSize="small" />
                ) : (
                    <Info color="primary" fontSize="small" />
                )}
                <Typography>
                    {errorTransferingMessages
                        ? "Something went wrong. Please contact support at hello@rpgmatch.org for assistance."
                        : "We're transferring your messages. This might take a moment — feel free to take a long rest."}
                </Typography>
            </Box>
            <ShouldRender returnNull condition={Boolean(transferingMessages)}>
                <LinearProgress
                    sx={{ borderRadius: "8px" }}
                    variant="determinate"
                    value={transferingMessagesProgress}
                />
            </ShouldRender>
        </Box>
    );
}
