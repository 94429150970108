import { api } from "./api";
import { endpoints } from "./endpoints";

type AddMetricProps = {
    metric: "accept" | "details" | "signup";
    namespace: "group_invitation";
};

async function addMetric({ metric, namespace }: AddMetricProps) {
    api.post(endpoints.cloudwatchAddMetric, { metric, namespace });
}

const cloudwatchProvider = {
    addMetric,
};

export { cloudwatchProvider };
