import { Auth } from "aws-amplify";
import changeEmailProvider from "@services/changeEmailProvider";

const useChangeEmail = () => {
    // Change user email attribute (Amplify/Cognito)
    const change = changeEmailProvider.changeCognitoEmail;
    // Update database (Neptune)
    const update = changeEmailProvider.changeEmail;
    // Resend user attribute confirmation code (Amplify/Cognito)
    const resend = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return await Auth.verifyUserAttribute(user, "email");
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return {
        change,
        update,
        resend,
    };
};

export default useChangeEmail;
