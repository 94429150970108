import { useEffect, useState } from "react";
import changeEmailProvider from "@services/changeEmailProvider";
import { useGlobalContext } from "context/AppContext";
import { GlobalContextType } from "./useGroupsList";

export default function useTransferingMessages() {
    /* States and Hooks */

    // When it is loading then the value is null, after loading it should be either false or true
    const [transferingMessages, setTransferingMessages] = useState<boolean | null>(null);
    const [errorTransferingMessages, setErrorTransferingMessages] = useState<boolean>(false);
    const [transferingMessagesProgress, setTransferingMessagesProgress] = useState<number>(0);

    const { personalProfile }: GlobalContextType = useGlobalContext();

    /* Functions */
    async function check() {
        const executionId: string | undefined =
            localStorage.getItem("ChangeEmail.ExecutionID") ||
            personalProfile?.profile?.["ChangeEmail.ExecutionID"]?.[0];

        if (!executionId) return; // In case there is no execution id then no e-mail should be changed

        const response = await changeEmailProvider.checkEmailChangeStatus(executionId);

        switch (response.Status) {
            case "in_progress": {
                setTransferingMessages(true);
                setErrorTransferingMessages(false);
                setTransferingMessagesProgress(parseInt(response.Progress));
                setTimeout(check, 3000);
                break;
            }
            case "failed": {
                setTransferingMessages(false);
                setErrorTransferingMessages(true);
                setTimeout(check, 3000);
                break;
            }
            default: {
                setTransferingMessages(false);
                setErrorTransferingMessages(false);
                localStorage.removeItem("ChangeEmail.ExecutionID");
                break;
            }
        }
    }

    /* Lifecycle */
    useEffect(() => {
        if (personalProfile?.id) check();
    }, [personalProfile?.id]);

    return {
        transferingMessages,
        errorTransferingMessages,
        transferingMessagesProgress,
    };
}
