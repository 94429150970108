import React, { useMemo } from "react";
import { Text, View } from "@aws-amplify/ui-react";
import "./ProfileAvailability.css";
import Availability from "components/Profile/components/ProfileBody/ProfileAvailability/Availability";
import AvailabilityEdit from "./AvailabilityEdit";
import { ShouldRender } from "../../ShouldRender/ShouldRender";
import WarningIcon from "@mui/icons-material/Warning";

const ProfileAvailability = ({ state, renderQuestions, isEditing, changeEditState, editState }) => {
    const hasAvaliability = !state?.profile?.noAvailability?.[0];

    const showComplete = useMemo(() => {
        return isEditing && !state?.profile?.lateness && state?.profile?.noAvailability?.[0] !== true;
    }, [isEditing, state?.profile?.lateness, state?.profile?.noAvailability]);

    return (
        <View
            id="availability"
            data-testid="profile-availability"
            className={`availability-container profile-component ${isEditing ? "edit" : ""} ${
                showComplete ? "not-completed" : ""
            }`}>
            <Text as="h2" className="availability-head-text">
                <span>Availability</span> {showComplete && <WarningIcon sx={{ color: "#FFB74D" }} />}
            </Text>
            {isEditing ? (
                <AvailabilityEdit
                    availabilityTable={state.profile}
                    changeEditState={changeEditState}
                    noAvailability={state?.profile?.noAvailability ? state?.profile?.noAvailability[0] : false}
                    editState={editState}
                />
            ) : (
                <ShouldRender
                    condition={hasAvaliability}
                    alternativeComponent={<Text as="p">This user has no availability.</Text>}>
                    <Availability availabilityTable={state?.profile} isEditing={isEditing} />
                </ShouldRender>
            )}
        </View>
    );
};

export default ProfileAvailability;
